<template>
  <div class="mymapM">
    <div class="van_nav_bar_view" style="width: 100%">
      <!-- height: 320px; position: fixed; top: 0; z-index: 20 -->
      <div class="home_map_icon_view">
        <img
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          @click="$router.go(-1)"
          alt=""
        />
        <div class="search_view">
          <!-- <img src="../../../assets/gray_address_bg.png" alt="" /> -->
          <van-icon
            name="search"
            style="margin-left: 5px"
            size="15px"
            color="#B5B5B5"
          />
          <input
            v-model="query"
            type="text"
            placeholder="大家都在搜：丹东草莓"
          />
        </div>
        <div class="search_text" @click="toSearch">搜索</div>
        <!-- <div class="search_all_view">
        </div> -->
      </div>
    </div>
    <div class="bot">
      <span>为你推荐</span>
    </div>
    <div class="classification">
      <div
        v-for="(item, index) in this.tuijiandata"
        :key="index"
        :style="{ width: dynamicwidth(item.name) }"
        @click="goSearch(item.name)"
      >
        <!-- @click="" -->
        {{ item.name }}
      </div>
    </div>
    <div class="lately">
      <span>最近搜索</span>
      <img @click="clear" src="../../../assets/delete_bg.png" alt="" />
    </div>
    <div class="classification">
      <div
        v-for="(item, index) in this.zuijindata"
        :key="index"
        :style="{ width: dynamicwidth(item.searchDesc) }"
        @click="goSearch(item.searchDesc)"
      >
        {{ item.searchDesc }}
      </div>
    </div>
    <!-- <div class="search_list_item">
      <div
        class="list_view"
        v-for="(item, index) in list"
        @click="toPath(item)"
        :key="index"
      >
        <div>
          <span>{{ item.name }}</span>
          <span>{{ item.juli }}</span>
        </div>
        <span
          style="
            font-size: 12px;
            color: #83898f;
            margin-top: 3px;
            margin-left: 15px;
          "
          >{{ item.address }}</span
        >
      </div>
    </div> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import { getSearch,clearSearchHistory } from "@/service/api/index.js";

export default {
  data() {
    return {
      center: [116.42792, 39.902896], //经度+纬度
      lists: [], //地点列表
      search_list: [], //搜索结果列表
      marker: "",
      list: [
        {
          name: "天府广场",
          juli: "1.23千米",
          address: "成都市青羊区人民南路一段86号",
        },
        {
          name: "天府广场时尚购物中心",
          juli: "1.2千米",
          address: "成都市青羊区天府广场站负一楼58",
        },
        {
          name: "rh（HD）",
          juli: "1.2千米",
          address: "成都市青羊区人民中路一段天府广场B1层附近",
        },
        {
          name: "天府广场今站购物中心",
          juli: "1.2千米",
          address: "成都市青羊区人民中路一段天府广场天府广场今站购物中心B1",
        },
        {
          name: "木清石灵（天府广场店）",
          juli: "1.2千米",
          address: "成都市青羊区天府广场站负一楼58",
        },
      ],
      noSearchShow: false, //无搜索结果提示，无搜索结果时会显示暂无搜索结果
      tuijiandata: [
        // {
        //   name: "排骨",
        // },
        // {
        //   name: "白菜",
        // },
        // {
        //   name: "芒果",
        // },
        // {
        //   name: "苹果",
        // },
        // {
        //   name: "五花肉",
        // },
      ],
      zuijindata: [
        // {
        //   name: "测试测试",
        // },
      ],
      query: "",
    };
  },
  created() {
    getSearch().then((res) => {
      this.tuijiandata = res.data.recommend;
      this.zuijindata = res.data.history;
    });
  },
  mounted() {
    const that_ = this;
    window.addEventListener(
      "message",
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module == "locationPicker") {
          //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          console.log("location", loc);
          that_.$router.push({
            path: "newAddress",
            query: {
              address: loc.poiaddress,
            },
          });
        }
      },
      false
    );
    // setTimeout(() => {
    //   this.adMap();
    //   // 我这里随便写的经纬度
    // }, 1000);
  },
  methods: {
    toPath(item) {
      this.$router.push({
        path: "newAddress",
        query: {
          address: item.address,
        },
      });
    },
    adMap() {
      //初始化地图
      var map = new AMap.Map("container", {
        zoom: 14, //缩放级别
        center: this.center, //设置地图中心点
        resizeEnable: true, //地图初始化加载定位到当前城市
      });
      //获取初始中心点并赋值
      var currentCenter = map.getCenter(); //此方法是获取当前地图的中心点
      this.center = [currentCenter.lng, currentCenter.lat]; //将获取到的中心点的纬度经度赋值给data的center
      console.log(this.center);
      //创建标记
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(currentCenter.lng, currentCenter.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      });
      // 将创建的点标记添加到已有的地图实例：
      map.add(this.marker);
      this.aMapSearchNearBy([116.42792, 39.902896], "北京", map);
    },

    aMapSearchNearBy(centerPoint, city, map) {
      AMap.service(["AMap.PlaceSearch"], function () {
        var placeSearch = new AMap.PlaceSearch({
          pageSize: 10, // 每页10条
          pageIndex: 1, // 获取第一页
          city: city, // 指定城市名(如果你获取不到城市名称，这个参数也可以不传，注释掉)
          map: map,
        });
        console.log("placeSearch", placeSearch);
        // 第一个参数是关键字，这里传入的空表示不需要根据关键字过滤
        // 第二个参数是经纬度，数组类型
        // 第三个参数是半径，周边的范围
        // 第四个参数为回调函数
        placeSearch.searchNearBy(
          "",
          centerPoint,
          1000,
          function (status, result) {
            console.log("aaaaa=", result);
            if (result.info === "OK") {
              var locationList = result.poiList.pois; // 周边地标建筑列表
              console.log("新列表===" + locationList); // 生成地址列表html
              createLocationHtml(locationList);
            } else {
              console.log("获取位置信息失败!");
            }
          }
        );
      });
    },
    inputListener() {
      var input = document.querySelector("input").value;
      if (input.length === 0) {
      } else {
      }
    },
    toSearch() {
      if (this.query == "") {
        Toast("请输入搜索商品名称");
      } else {
        this.$router.replace({
        // this.$router.replace({
          path: "/detailsAddress",
          query: {
            keyWord: this.query,
          },
        });
      }
    },
    goSearch(data) {
      this.query = data;
      this.toSearch();
    },
    clear() {
      clearSearchHistory().then(res=>{
        if(res.status == 200){
          this.zuijindata = [];
          Toast("删除成功");
        }
      })
    },
    dynamicwidth(data) {
      return String(data).length * 20 + 12 + "px";
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .van_nav_bar_view {
    max-width: 540px;
  }
  .search_input_view {
    max-width: 540px;
    width: 100%;
    background: turquoise;
  }
}
.search_list_item {
  // position: absolute;
  // top: 330px;
  max-width: 540px;
  width: 100%;
  background: white;
  width: 100%;
}
.search_input_view {
  width: 80%;
  height: 30px;
  line-height: 30px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  margin: 0 auto;
  padding-left: 15px;
  border: 1px solid #00b05a;

  input {
    flex: 1;
    height: 30px;
    margin-right: 10px;
    margin-left: 5px;
    border: none;
    background: none;
  }
}

.con-box {
  .mapbox {
    width: 100%;
    height: 236px;
    padding: 10px 0;
    background: #eceeee;
    .map {
      width: 100%;
      height: 236px;
    }
  }
}

.list_view {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #e5e5e5;
  background: white;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
    justify-content: space-between;
    span:frist-of-type {
      font-size: 15px;
      font-weight: 400;
      color: black;
    }
    span:last-of-type {
      font-size: 12px;
      color: #787d84;
      font-weight: 400;
      margin-right: 15px;
    }
  }
  span {
  }
}
.search_all_view {
  width: 100%;
  background: white;
  height: 40px;
}
.search_view {
  // width: 250px;
  // height: 30px;
  // border-radius: 20px;
  // margin: 0 auto;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  width: 70%;
  height: 25px;
  background: white;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #00b05a;
}
.search_view img {
  width: 16px;
  height: 16px;
  margin-left: 7px;
}
.search_view input {
  flex: 1;
  margin-left: 4px;
  border: none;
  background: none;
  font-size: 14px;
}
.search_view input::-webkit-input-placeholder {
  font-size: 12px;
}

.home_map_icon_view {
  max-width: 540px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 44px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  top: 0;
  background: white;
  box-sizing: border-box;
}
.search_text {
  font-size: 14px;
  color: #00b05a;
}
.bot {
  padding: 0 10px;
  margin: 20px 0;
  span {
    font-size: 14px;
    color: #818181;
  }
}
.lately {
  padding: 0 10px;
  margin: 10px 0 20px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    color: #818181;
  }
  img {
    width: 16px;
    height: 16px;
  }
}
.classification {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  div {
    height: 24px;
    background: #e7e7e7;
    border-radius: 12px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 10px 0;
  }
}
</style>
